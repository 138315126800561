<!--
 * @Description:人行道停车运营管理 运营管理 异常放行 abnormalRelease
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-06-09 11:56:38
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select v-model="searchForm.parkId"
                         clearable
                         filterable
                         placeholder="全部">
                <el-option v-for="item in parkNameList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="订单编号">
              <el-input v-model="searchForm.orderSequence"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色">
              <el-select v-model="searchForm.plateColor"
                         clearable
                         filterable
                         placeholder="全部">
                <el-option v-for="item in plateColorList"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="放行时间">
              <el-date-picker v-model="time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="操作人">
              <el-input v-model="searchForm.operator"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>异常放行</span>
        <!-- <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">
          导 出
        </el-button> -->
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import duration from '@/utils/duration'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  //监听属性 类似于data概念
  computed: {},
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'orderSequence',
          label: '订单编号',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'plateColor',
          label: '车牌颜色',
        },
        {
          prop: 'createdTime',
          label: '放行时间',
        },
        {
          prop: 'operator',
          label: '操作人',
        },
        {
          prop: 'parkDuration',
          label: '停车时长',
        },
        {
          prop: 'bak',
          label: '备注',
        },
      ],
      parkNameList: [],//停车场
      plateColorList: [],// 车牌颜色
      time: null,//生成时间
      // 时间快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkList()
    this.getColorDict()
    this.queryTableList()
  },
  //方法集合
  methods: {
    //获取表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$abnormalRelease.getListByPage(this.searchForm).then(res => {
        if (res.resultEntity.list) {
          res.resultEntity.list.forEach(item => {
            item.parkDuration = duration(item.parkDuration)
          })
        }
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    //查询按钮
    queryButton () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.time) {
        this.searchForm.startTime = this.time[0]
        this.searchForm.endTime = this.time[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryTableList();
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.time = null
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 停车场
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        whereStr: [
          {
            "colName": "park_type_code",
            "value": "2"
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.plateColorList = response.resultEntity
      })
    },
  },

  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.lineItem {
  margin-top: 10px;
  color: #79baf7;
  font-size: 16px;
}
</style>
